/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'


import { Alert, Button, Form, Modal } from 'react-bootstrap';
import { useAuth } from '../auth';
import { ADD_PREDICTIVEMAINTENANCE, GET_PRODUCT_LIST_BY_BRAND_TEXT } from '../../../urls';
import { blackboxIdContext } from '../profile/components/contexts/BlackboxIdContext';
import { useLocation } from 'react-router-dom';
import { useDebounce } from '../../../_elfaiotbbx/helpers';

interface Product {
    id: string
    brand: string;
    model: string;
}

export function AddReperation() {

    const { currentUser } = useAuth()
    const [id, setId] = useState(currentUser?.id)
    const currentBlackBoxId = useContext(blackboxIdContext);
    const location = useLocation();
    const uid = (location.state as { uid?: string })?.uid;
    const [productList, setProductList] = useState<Product[]>([])
    const [searchTerm, setSearchTerm] = useState('');

    const token = currentUser?.token

    const [loading, setLoading] = useState(false)



    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [alertVariant, setAlertVariant] = useState("danger")
    const [showAlert, setShowAlert] = useState(false)
    const [alertText, setAlertText] = useState("Aynı node id'li cihaz eklenemez")
    const [numbeOfButtonsNumber, setNumbeOfButtonsNumber] = useState(1)
    const [selectedValue, setSelectedValue] = useState('');

    const [inputList, setInputList] = useState<any>([])



    function addInput() {
        if (inputList.length < 16) {
            setInputList([...inputList, inputList.length])
            console.log(inputList)

        }

    }
    const createHeader = () => {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', "" + token?.toString());
        return headers
    }


    async function searchDistributorId(distrubitorId: any) {
        const response = await fetch(ADD_PREDICTIVEMAINTENANCE + `?id=${distrubitorId.target.valu}`, {
            method: "GET",
            headers: createHeader(),

        })

            .then(
                res => res.json()
            )
            .then(
                res => {


                    // handleClose()

                }
            )
    }

    const getReparationType = () => {
        return (
            <>
                <option value={0}>Seçenekler</option>
                <option value={1}>Rulman</option>
                <option value={3}>Kasnak</option>
                {/* <option value={5}>Halat</option> */}
                {/* <option value={7}>Kanca</option> */}
                <option value={9}>Motor</option>

                <option value={2}>Role</option>
                <option value={4}>Kontaktör</option>
                <option value={6}>Button</option>

            </>
        )
    }

    function isEven(number) {
        return number % 2 === 0;
    }

    const [ioList, setIoList] = useState<any>([])
    const [ioListValue, setIoListValue] = useState<any>([])
    const [ioListDescription, setIoListDescription] = useState<any>([])

    const [lastInput, setLastInput] = useState("")
    const [lastValue, setLastValue] = useState("")
    const [lastDescription, setLastDescription] = useState("")

    const changeLastDescription = input => setLastDescription(input)
    const changeLastInput = (input) => { setLastInput(input); }
    const changeLastValue = (input) => { setLastValue(input); }

    const [description, setDescription] = useState("")
    const changeDescriptionOnRep = (e) => setDescription(e.target.value)
    const [initializeInputList, setInitializeInputList] = useState(["input seç", "Input-0", "Input-1", "Input-2", "Input-3", "Input-4", "Input-5",
        "Input-6", "Input-7", "Input-8", "Input-9", "Input-10", "Input-11", "Input-12", "Input-13", "Input-14",
        "Input-15",])
    

    const [dataType, setDataType] = useState(0)
    const [isTypeEven, setIsTypeEven] = useState(false)
    const changeDataType = input => {
        setDataType(input.target.value);
        setIsTypeEven(isEven(input.target.value))
    }

   

    
    const getCanioInput = () => {
        return initializeInputList.map((input, index) => (
            <option key={index} value={input.toString()}>{input}</option>
        ));
    };
    useEffect(() => {
        console.log(2)
    }, [initializeInputList])

    function setValueForInput(input: string, value: any) {
        console.log(input, value)
    }

    function changeInputList() {
        setInitializeInputList(initializeInputList.filter(input => input !== lastInput))
    }

    function isNumericString(input) {
        return /^\d+$/.test(input);
    }

    function saveOneInput() {
        if (lastInput != "" && lastValue != "" && isNumericString(lastValue) && lastInput != "input seç") {

            setIoList([...ioList, lastInput])
            setIoListValue([...ioListValue, lastValue])
            setIoListDescription([...ioListDescription, lastDescription])

            changeInputList()

            handleClose()

            changeLastInput("")
            changeLastValue("")
            changeLastDescription("")
        }

    }

    function sendData() {

        const data = {
            "blackboxId": currentBlackBoxId[0],
            "description": description,
            "uid": uid,
            "ioNumber": dataType,
            "ioDeviceId": selectedValue,
        }
        addPredictivemaintenance(data)
        console.log(data)


    }
    async function addPredictivemaintenance(data: any) {

        //const response = await fetch(BLACKBOXES_DEVICE_LIST_URL+`?blackboxId=${blackboxDetails["serialNumber"]}`, {
        const response = await fetch(ADD_PREDICTIVEMAINTENANCE, {
            method: "POST",
            headers: createHeader(),
            body: JSON.stringify(data)
        })
            .then(
                res => res.json()
            )
            .then(
                res => {
                    console.log(res)
                }
            )

    }


    const handleSelectChange = (event) => {
        setSelectedValue(event.target.value);
        setSearchTerm(event.target.value);
    };

    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    useEffect(() => {
        if (debouncedSearchTerm) {
            fetch(GET_PRODUCT_LIST_BY_BRAND_TEXT + `?searchtext=${debouncedSearchTerm}&userId=${currentUser?.id}`, {
                method: "GET",
                headers: createHeader(),

            })
                .then(response => response.json())
                .then(data => setProductList(data))
                .catch(error => console.error('Error fetching data:', error));
        } else {
            setProductList([]);
        }
    }, [debouncedSearchTerm]);

  
    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
        setSelectedValue('');  // Arama yaparken seçimi temizleyin
    };
    return (


        <>

            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_account_profile_details'
                    aria-expanded='true'
                    aria-controls='kt_account_profile_details'
                >
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Bakım yapılandırma</h3>
                    </div>
                </div>
                {id != undefined &&
                    <div id='kt_account_profile_details' className='collapse show'>
                        <form noValidate className='form'>
                            <div className='card-body border-top p-9'>


                                <div className='row mb-6'>
                                    <label className='col-lg-2 col-form-label fw-bold fs-6'>
                                        <span className='required'>Input</span>
                                    </label>

                                    <div className='col-lg-4 fv-row'>
                                        <select
                                            className='form-select form-select-solid form-select-lg fw-bold'

                                            onClick={(e) => changeDataType(e)}
                                        >
                                            {getCanioInput()}
                                        </select>
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'></div>
                                        </div>
                                    </div>

                                </div>

                                <div className='row mb-6'>

                                    <label className='col-lg-2 col-form-label fw-bold fs-6'>
                                        <span className='required'>Açıklama</span>
                                    </label>

                                    <div style={{ maxWidth: "500px", width: "100%", display: "inline-block", }}>
                                        <Form.Control aria-label="Default select example" id="graphicType" onChange={e => changeDescriptionOnRep(e)}>

                                        </Form.Control>
                                    </div>
                                </div>
                                
                                <div className='row mb-6'>
                                    <label htmlFor="searchBar">Ara:</label>
                                    <input
                                        type="text"
                                        id="searchBar"
                                        className="form-control"
                                        value={searchTerm}
                                        onChange={handleInputChange}
                                        placeholder="Aramak için bir şeyler yazın..."
                                    />
                                </div >
                                <div >
                                    {productList.length > 0 && (
                                        <select
                                            id="comboBox"
                                            className="form-control mt-2"
                                            value={selectedValue}
                                            onChange={handleSelectChange}
                                            size={5}  // Birden fazla seçeneği göstermek için
                                        >
                                            <option value="">Seçiniz...</option>
                                            {productList.map(item => (
                                                <option key={item.id} value={item.id}>
                                                    {item.brand + " " + item.model}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                </div>

                            </div>

                            <Alert key={alertVariant} variant={alertVariant} show={showAlert}>
                                {alertText}
                            </Alert>
                            <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                <a className='btn btn-primary' onClick={sendData}>
                                    {!loading && 'Değişikleri Kaydet'}
                                    {loading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </a>
                            </div>

                        </form>

                    </div >
                }

            </div >
        </>

    )
}
