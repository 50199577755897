
export const rope = {
    "indicator":true,
    "speed":true,
    "angle":true,
    "canio":false,
    "productId":5
}

export const motor = {
    "indicator":false,
    "speed":false,
    "angle":false,
    "canio":false,
    "productId":9
}

export const button = {
    "indicator":false,
    "speed":false,
    "angle":false,
    "canio":true,
    "productId":6
}


export const ProductTypeList = [
    
    rope,
    motor,
    button
]