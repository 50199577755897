import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_elfaiotbbx/layout/core'
import { WorkingTimeList } from '../../../_elfaiotbbx/partials/widgets/lists/WorkingTimeList'
import { ReparationMainWindowList } from './ReparationMainWindowList'
import { AddReperation } from './AddReparation'
import { IndicatorMainWindow } from './IndicatorMainWindow'
import { LimitLog } from './LimitLog'
import { AddProduct } from './AddProduct'
import { ListProduct } from './ListProduct'




const reparationBreadCrumbs: Array<PageLink> = [
    {
        title: 'Reparation',
        path: '/crafted/reparation/',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const Reparation = () => {
    return (
        <Routes>
            <Route element={<Outlet />}>
                
                 <Route
                    path='availablebalance'
                    element={
                        <>
                            <PageTitle breadcrumbs={reparationBreadCrumbs}>Yaklaşık Kullanım Ömrü</PageTitle>
                            <ReparationMainWindowList />
                        </>
                    }
                />
                 <Route
                    path='addreparation'
                    element={
                        <>
                            <PageTitle breadcrumbs={reparationBreadCrumbs}>Karakutu Ekle</PageTitle>
                            <AddReperation />
                        </>
                    }
                />
                <Route
                    path='indicatorreparation'
                    element={
                        <>
                            <PageTitle breadcrumbs={reparationBreadCrumbs}>Indikatör</PageTitle>
                            <IndicatorMainWindow />
                        </>
                    }
                />
                <Route
                    path='systemlog'
                    element={
                        <>
                            <PageTitle breadcrumbs={reparationBreadCrumbs}>Indikatör</PageTitle>
                            <LimitLog />
                        </>
                    }
                />
                
                
            </Route>
            
        </Routes>
    )
}

export default Reparation
