/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'
import { blackboxIdContext } from '../profile/components/contexts/BlackboxIdContext';
import { useAuth } from '../auth';
import { Alert, Form, OverlayTrigger } from 'react-bootstrap';
import { ProductTypeList } from './RelatedProductList';
import { BLACKBOXES_DEVICE_LIST_URL, BLACKBOXES_DRIVERCOMMAND_GETALL_URL, CRANE_ADD_DEVICE_URL, GET_PRODUCT_LIST_BY_BRAND_TEXT } from '../../../urls';
import { get } from 'http';
import { useDebounce } from '../../../_elfaiotbbx/helpers';



interface Product {
    id: string
    brand: string;
    model: string;
}

const DefineDevice: React.FC = () => {

    const { currentUser, logout } = useAuth()
    const [id, setId] = useState(currentUser?.id)
    const currentBlackBoxId = useContext(blackboxIdContext);
    const token = currentUser?.token
    const [loading, setLoading] = useState(false)


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [alertVariant, setAlertVariant] = useState("danger")
    const [showAlert, setShowAlert] = useState(false)
    const [alertText, setAlertText] = useState("Cihazları kontrol edin")

    const defaultValue = {
        "indicator": false,
        "angle": false,
        "speed": false
    }
    
    const [selectedIndicator, setSelectedIndicator] = useState<any>("")
    const [selectedAngleLim, setSelectedAngleLim] = useState<any>("")
    const [selectedCatMotor, setSelectedCatMotor] = useState<any>("")
    const [selectedBridgeMotor, setSelectedBridgeMotor] = useState<any>("")
    const [selectedHookMotor, setSelectedHookMotor] = useState<any>("")
    const [selectedCanio, setSelectedCanio] = useState<any>("")
    const [canbusDevices, setCanbusDevices] = useState<any>([])
    const [modbusDevices, setModbusDevice] = useState<any>([])
    const [canbusOptionDevices, setCanbusOptionDevices] = useState<any>([])
    const [optionDeviceModbusList, setOptionModbusDeviceList] = useState<any>([])
  
    const createHeader = () => {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', "" + token?.toString());
        return headers
    }

  
    useEffect(() => {
        getDeviceList()
        getDriverList()
        }, []);


    function selectIndicator(e:any){
        setSelectedIndicator(e.target.value)
    }
    function selectAngleLim(e:any){
        setSelectedAngleLim(e.target.value)
    }
    function selectCatMotor(e:any){
        setSelectedCatMotor(e.target.value)
    }
    function selectHookMotor(e:any){
        setSelectedHookMotor(e.target.value)
    }
    function selectBridgeMotor(e:any){
        setSelectedBridgeMotor(e.target.value)
    }
    function selectCanio(e:any){
        setSelectedCanio(e.target.value)
    }

    async function saveDeviceList(deviceList) {

        const response = await fetch(CRANE_ADD_DEVICE_URL , {
          method: "POST",
          headers: createHeader(),
          body:JSON.stringify(deviceList)
        })
        if (response.status === 401) {
            logout();
            return;
        }
        const responseData = await response.json()
        if (response.status === 401) {
            logout();
            return;
        }
        else if(response.status === 200){
            console.log(responseData)
            setShowAlert(true)
            setAlertText("Cihazlar başarıyle kaydedildi.")
            setAlertVariant("primary")
            setTimeout(() => setShowAlert(false), 2000)
        }
        else if(response.status === 400){
            console.log(responseData)
            setShowAlert(true)
            setAlertText("Cihazları kontrol edin.")
            setAlertVariant("danger")
            setTimeout(() => setShowAlert(false), 2000)
        }
        else{
            console.log(responseData)
            setShowAlert(true)
            setAlertText("Cihazları kontrol edin.")
            setAlertVariant("danger")
            setTimeout(() => setShowAlert(false), 2000)
        }
        
         
    }
      
    async function getDeviceList() {
    
        const response = await fetch(BLACKBOXES_DEVICE_LIST_URL + `?blackboxId=${currentBlackBoxId[0]}`, {
          method: "GET",
          headers: createHeader()
        })
        if (response.status === 401) {
            logout();
            return;
        }
        const responseData = await response.json()
        //console.log("*-*--*-**")
        console.log(responseData)
        setCanbusDevices(responseData)
        setCanbusOptionDevices(getOptionCanbusDevice(responseData))
         
      }

      async function getDriverList() {
    
        //const response = await fetch(BLACKBOXES_DEVICE_LIST_URL+`?blackboxId=${blackboxDetails["serialNumber"]}`, {
        const response = await fetch(BLACKBOXES_DRIVERCOMMAND_GETALL_URL + `?blackboxId=${currentBlackBoxId[0]}`, {
          method: "GET",
          headers: createHeader()
        })
        if (response.status === 401) {
            logout();
            return;
        }
        const responseData = await response.json()      
        setOptionModbusDeviceList( setOptionDevices(responseData))
      }
      
      const setOptionDevices = (list) => {
        return [
            <option key="default" value="0" >
             Uygun bir cihaz seçin
            </option>,
            ...list.map((device, index) => (
            <option key={index} value={device["driverId"]}>{device["driverId"]+" - "+device["description"]}</option>
        
        )) 
        ]
      }
      const getOptionCanbusDevice = (list) => {
        return [
            <option key="default" value="0" >
             Uygun bir cihaz seçin
            </option>,
            ...list.map((device, index) => (
            <option key={index} value={device["uid"]}>{nameChooser(device["deviceName"])}{" - "+device["description"]}</option>
        
        )) 
        ]
    };
    
    function checkDevices(){
        
        var checkList = true
        canbusDevices.forEach(element => {
            //console.log(element)
            if(element["uid"] === selectedIndicator ){
                if(element["deviceName"] != "2"){
                    checkList = false
                    return 
                }
            }
            if(element["uid"] === selectedCanio ){
                if(element["deviceName"] != "4"){
                    checkList = false
                    return 
                }
            }
            if(element["uid"] === selectedAngleLim ){
                if(element["deviceName"] != "3"){
                    checkList = false
                    return 
                }
            }
        });
        return checkList
    }
    function sendData(){
        
        if(!checkDevices()) {
            setAlertVariant("danger")
            setAlertText("Cihazları kontrol edin")
            setShowAlert(true)
            setTimeout(() => setShowAlert(false), 2000)
            return
        }
        const sendData = {
            "indicatorId": selectedIndicator,
            "canioId": selectedCanio,
            "angleLimId": selectedAngleLim,
            "craneCatMotor": selectedCatMotor,
            "craneBridgeMotor": selectedBridgeMotor,
            "craneHookMotor":selectedHookMotor,
            "blackboxId":currentBlackBoxId[0]
        }
        console.log(sendData)
        saveDeviceList(sendData)
    }
    const nameChooser = (device: string) => {


        if (device == "1") return "Çift giriş indikatör"
        else if (device == "2") return "İndikatör"
        else if (device == "3") return "Halat açı"
        else if (device == "4") return "Canio"
        else if (device == "5") return "Fren"
    }
    return (

        <>

            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_account_profile_details'
                    aria-expanded='true'
                    aria-controls='kt_account_profile_details'
                >
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Ürün Ekleme</h3>
                    </div>
                </div>
                {id != undefined &&
                    <div id='kt_account_profile_details' className='collapse show'>
                        <form noValidate className='form'>
                            <div className='card-body border-top p-9'>


                                
                                <div className='row mb-6'>
                                    <div style={{ maxWidth: "200px", width: "100%", display: "inline-block", marginTop: "7px" }}>
                                        <Form.Label aria-label="Default select example" id="İndikatör">
                                            İndikatör 
                                        </Form.Label>
                                    </div>
                                    <div className='col-lg-4 fv-row'>
                                        <select
                                            className='form-select form-select-solid form-select-lg fw-bold'

                                            onClick={(e) => selectIndicator(e)}
                                        >
                                            {canbusOptionDevices}
                                        </select>
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <div style={{ maxWidth: "200px", width: "100%", display: "inline-block", marginTop: "7px" }}>
                                        <Form.Label aria-label="Default select example" id="Halat">
                                            Halat Açı
                                        </Form.Label>
                                    </div>
                                    <div className='col-lg-4 fv-row'>
                                        <select
                                            className='form-select form-select-solid form-select-lg fw-bold'

                                            onClick={(e) => selectAngleLim(e)}
                                        >
                                            {canbusOptionDevices}
                                        </select>
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <div style={{ maxWidth: "200px", width: "100%", display: "inline-block", marginTop: "7px" }}>
                                        <Form.Label aria-label="Default select example" id="Canıo">
                                            Canıo 
                                        </Form.Label>
                                    </div>
                                    <div className='col-lg-4 fv-row'>
                                        <select
                                            className='form-select form-select-solid form-select-lg fw-bold'

                                            onClick={(e) => selectCanio(e)}
                                        >
                                            {canbusOptionDevices}
                                        </select>
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <div style={{ maxWidth: "200px", width: "100%", display: "inline-block", marginTop: "7px" }}>
                                        <Form.Label aria-label="Default select example" id="Köprü">
                                            Köprü Motor
                                        </Form.Label>
                                    </div>
                                    <div className='col-lg-4 fv-row'>
                                        <select
                                            className='form-select form-select-solid form-select-lg fw-bold'

                                            onClick={(e) => selectBridgeMotor(e)}
                                        >
                                            {optionDeviceModbusList}
                                        </select>
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <div style={{ maxWidth: "200px", width: "100%", display: "inline-block", marginTop: "7px" }}>
                                        <Form.Label aria-label="Default select example" id="Kedi">
                                            Kedi Motor
                                        </Form.Label>
                                    </div>
                                    <div className='col-lg-4 fv-row'>
                                        <select
                                            className='form-select form-select-solid form-select-lg fw-bold'

                                            onClick={(e) => selectCatMotor(e)}
                                        >
                                            {optionDeviceModbusList}
                                        </select>
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <div style={{ maxWidth: "200px", width: "100%", display: "inline-block", marginTop: "7px" }}>
                                        <Form.Label aria-label="Default select example" id="Kanca">
                                            Kanca Motor
                                        </Form.Label>
                                    </div>
                                    <div className='col-lg-4 fv-row'>
                                        <select
                                            className='form-select form-select-solid form-select-lg fw-bold'

                                            onClick={(e) => selectHookMotor(e)}
                                        >
                                            {optionDeviceModbusList}
                                        </select>
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'></div>
                                        </div>
                                    </div>
                                </div>
                               
                                
                                
                            </div>

                            <Alert key={alertVariant} variant={alertVariant} show={showAlert}>
                                {alertText}
                            </Alert>
                            <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                <a className='btn btn-primary' onClick={sendData}>
                                    {!loading && 'Değişikleri Kaydet'}
                                    {loading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </a>
                            </div>

                        </form>

                    </div >
                }

            </div >
        </>
    )
}

export { DefineDevice }
