import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_elfaiotbbx/layout/core'
import { AddAIProduct } from './AddAIProduct'
import { DefineDevice } from './DefineDevice'
import { ListProduct } from '../reparation/ListProduct'
import { AddProduct } from '../reparation/AddProduct'






const aiMaintanceBreadCrumbs: Array<PageLink> = [
    {
        title: 'Bakım',
        path: '/dev/AIMaintance/',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const AIMaintancePage = () => {
    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path='addAIProduct'
                    element={
                        <>
                            <PageTitle breadcrumbs={aiMaintanceBreadCrumbs}>Roller</PageTitle>
                            <AddAIProduct />
                        </>
                    }
                />

            </Route>
            <Route element={<Outlet />}>
                <Route
                    path='definedevice'
                    element={
                        <>
                            <PageTitle breadcrumbs={aiMaintanceBreadCrumbs}>Cihaz Tanımla</PageTitle>
                            <DefineDevice />
                        </>
                    }
                />

            </Route>
            <Route
                path='listproduct'
                element={
                    <>
                        <PageTitle breadcrumbs={aiMaintanceBreadCrumbs}>Ürün Listele</PageTitle>
                        <ListProduct />
                    </>
                }
            />
            <Route
                path='addproduct'
                element={
                    <>
                        <PageTitle breadcrumbs={aiMaintanceBreadCrumbs}>Ürün Ekle</PageTitle>
                        <AddProduct />
                    </>
                }
            />


        </Routes>
    )
}

export default AIMaintancePage
