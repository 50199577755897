/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'


import { Alert, Button, Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useAuth } from '../auth';
import { ADD_PREDICTIVEMAINTENANCE, ADD_PRODUCT_BY_USERID } from '../../../urls';
import { blackboxIdContext } from '../profile/components/contexts/BlackboxIdContext';
import { useLocation } from 'react-router-dom';



export function AddProduct() {

    const { currentUser, logout } = useAuth()
    const [id, setId] = useState(currentUser?.id)
    const currentBlackBoxId = useContext(blackboxIdContext);
    const location = useLocation();
    const uid = (location.state as { uid?: string })?.uid;


    const token = currentUser?.token

    const [loading, setLoading] = useState(false)



    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [alertVariant, setAlertVariant] = useState("danger")
    const [showAlert, setShowAlert] = useState(false)
    const [alertText, setAlertText] = useState("Aynı node id'li cihaz eklenemez")
    const [numbeOfButtonsNumber, setNumbeOfButtonsNumber] = useState(1)
    const [stockCode, setStockCode] = useState("")
    const [inputList, setInputList] = useState<any>([])


    const createHeader = () => {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', "" + token?.toString());
        return headers
    }



    const getReparationType = () => {
        return (
            <>
                {/* süre */}
                <option value={0}>Seçenekler</option>
                <option value={1}>Rulman</option>
                <option value={3}>Kasnak</option>
                <option value={9}>Motor</option>
                {/* trigger */}
                <option value={2}>Role</option>
                <option value={4}>Kontaktör</option>
                <option value={6}>Button</option>

                {/* ağrılık */}
                <option value={5}>Halat</option> 
                <option value={7}>Kanca</option> 

                {/* <option value={10}>Diğer</option> */}

            </>
        )
    }






    const [isCustomized, setIsCustomized] = useState(false)
    const changeProductType = (e) => {
        if (e === 10) setDeviceTimeType("1")
        else if (e === 5 || e == 7) setDeviceTimeType("4")
        else if (isEven(e)) setDeviceTimeType("2")
        else setDeviceTimeType("3")
    }
    const [deviceType, setDeviceType] = useState("0")
    const [deviceTimeType, setDeviceTimeType] = useState("1") //1 -> custumized 2-> trigger 3-> time 4-> weight
    const [description, setDescription] = useState("")
    const changeDescriptionOnRep = (e) => setDescription(e.target.value)
    const [brand, setBrand] = useState("")
    const [model, setModel] = useState("")
    const [maxUsageTime, setMaxUsageTime] = useState(0)
    const [isActive, setIsActive] = useState("0")
    const [choosenTime, setChoosenTime] = useState("0")
    const changeDataType = input => {

        if (input.target.value == "10") {
            setIsCustomized(true)
            setDeviceType(input.target.value);
            changeProductType(parseInt(input.target.value))
        }
        else {
            setIsCustomized(false)
            setDeviceType(input.target.value);
            changeProductType(parseInt(input.target.value))
        }

    }

    function isEven(number) {
        return number % 2 === 0;
    }


    useEffect(() => {

    }, [isCustomized, deviceTimeType])


    function isNumeric(str) {
        return /^[0-9]+$/.test(str);
    }


    function sendData() {
        
        const data = {
            "deviceType": deviceType,
            "description": description,
            "brand": brand,
            "model": model,
            "maxUsageTime": maxUsageTime,
            "isActive": isActive === "0" ? false : true,
            "choosenTime": choosenTime,
            "deviceTimeType": deviceTimeType,
            "userId": currentUser?.id,
            "stockCode" : stockCode

        }
        console.log(data)
        if (!isNumeric(maxUsageTime)) {
            setAlertText("Girelen sürenin sayı olması gerekiyor.")
            setShowAlert(true)
            setTimeout(() => setShowAlert(false), 2000)
        }
        else if (deviceType === "10" && choosenTime === "0") {
            setAlertText("Cihaz süre türünü seçemeniz gerekiyor")
            setShowAlert(true)
            setTimeout(() => setShowAlert(false), 2000)
        }
        else if (deviceType != "0") {
            
            console.log(data)
            AddProduct(data)
        }else{
            setAlertText("Girilen bilgiler eksik ve ya yanlış")
            setShowAlert(true)
            setTimeout(() => setShowAlert(false), 2000)
        }

    }
    async function AddProduct(data: any) {
        console.log(ADD_PRODUCT_BY_USERID + `?id=${currentUser?.id}`)
        //const response = await fetch(BLACKBOXES_DEVICE_LIST_URL+`?blackboxId=${blackboxDetails["serialNumber"]}`, {
        const response = await fetch(ADD_PRODUCT_BY_USERID + `?id=${currentUser?.id}`, {
            method: "POST",
            headers: createHeader(),
            body: JSON.stringify(data)
        })
        if (response.status === 401) {
            logout();
            return;
        }
        if (response.status === 400) {
            setAlertText("Kaydedilemedi")
            setShowAlert(true)
            setTimeout(() => setShowAlert(false), 2000)
        }
        if (response.status === 200) {
            setAlertText("Ürün başarılı bir şekilde kaydedildi.")
            setAlertVariant("primary")
            setShowAlert(true)
            setTimeout(()=> setShowAlert(false),2000)
        }
        const responseData = await response.json()
        console.log(responseData)


    }
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip-2" {...props}>
            "Diğer" dışındaki tüm cihazları sistem otomatik yapılandırır.
        </Tooltip>
    );
    return (


        <>

            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_account_profile_details'
                    aria-expanded='true'
                    aria-controls='kt_account_profile_details'
                >
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Ürün Ekleme</h3>
                    </div>
                </div>
                {id != undefined &&
                    <div id='kt_account_profile_details' className='collapse show'>
                        <form noValidate className='form'>
                            <div className='card-body border-top p-9'>


                                <div className='row mb-6'>
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                    >
                                        <label className='col-lg-2 col-form-label fw-bold fs-6'>
                                            <span className='required'>Eklenecek Tür</span>
                                        </label>
                                    </OverlayTrigger>
                                    <div className='col-lg-4 fv-row'>
                                        <select
                                            className='form-select form-select-solid form-select-lg fw-bold'

                                            onClick={(e) => changeDataType(e)}
                                        >
                                            {getReparationType()}
                                        </select>
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'></div>
                                        </div>
                                    </div>

                                </div>
                                <div className='row mb-6'>
                                    <div style={{ maxWidth: "200px", width: "100%", display: "inline-block", marginTop: "7px" }}>
                                        <Form.Label aria-label="Default select example" id="graphicType">
                                            Açıklama
                                        </Form.Label>
                                    </div>
                                    <div style={{ maxWidth: "500px", width: "100%", display: "inline-block", }}>
                                        <Form.Control aria-label="Default select example" id="graphicType" onChange={e => setDescription(e.target.value)}>

                                        </Form.Control>
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <div style={{ maxWidth: "200px", width: "100%", display: "inline-block", marginTop: "7px" }}>
                                        <Form.Label aria-label="Default select example" id="graphicType">
                                            Marka
                                        </Form.Label>
                                    </div>
                                    <div style={{ maxWidth: "500px", width: "100%", display: "inline-block", }}>
                                        <Form.Control aria-label="Default select example" id="graphicType" onChange={e => setBrand(e.target.value)}>

                                        </Form.Control>
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <div style={{ maxWidth: "200px", width: "100%", display: "inline-block", marginTop: "7px" }}>
                                        <Form.Label aria-label="Default select example" id="graphicType">
                                            Model
                                        </Form.Label>
                                    </div>
                                    <div style={{ maxWidth: "500px", width: "100%", display: "inline-block", }}>
                                        <Form.Control aria-label="Default select example" id="graphicType" onChange={e => setModel(e.target.value)}>

                                        </Form.Control>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <div style={{ maxWidth: "200px", width: "100%", display: "inline-block", marginTop: "7px" }}>
                                        <Form.Label aria-label="Default select example" id="graphicType">
                                            Stok Kodu
                                        </Form.Label>
                                    </div>
                                    <div style={{ maxWidth: "500px", width: "100%", display: "inline-block", }}>
                                        <Form.Control aria-label="Default select example" id="graphicType" onChange={e => setStockCode(e.target.value)}>

                                        </Form.Control>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <div style={{ maxWidth: "200px", width: "100%", display: "inline-block", marginTop: "7px" }}>
                                        <Form.Label aria-label="Default select example" id="graphicType">
                                            {deviceTimeType == "1" ?
                                                "özelleştirildi" :
                                                deviceTimeType == "2" ?
                                                    "Maximum çekme sayısı (Tetik)" :
                                                    deviceTimeType == "4" ?
                                                        "Toplam Taşıyabileceği max ağırlık" :
                                                        "Maximum çalışma süresi (Saat)"}
                                        </Form.Label>
                                    </div>
                                    <div style={{ maxWidth: "500px", width: "100%", display: "inline-block", }}>
                                        <Form.Control aria-label="Default select example" id="graphicType" onChange={e => setMaxUsageTime(parseInt(e.target.value))}>

                                        </Form.Control>
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <div style={{ maxWidth: "200px", width: "100%", display: "inline-block", marginTop: "7px" }}>
                                        <Form.Label aria-label="Default select example" id="graphicType">
                                            Durum
                                        </Form.Label>
                                    </div>

                                    <div className='col-lg-4 fv-row'>
                                        <select
                                            className='form-select form-select-solid form-select-lg fw-bold'
                                            onChange={e => setIsActive(e.target.value)}
                                        >
                                            <option value='0'>Pasif</option>
                                            <option value='1'>Aktif</option>

                                        </select>

                                    </div>
                                </div>
                                {isCustomized &&
                                    <>
                                        <div className='row mb-6'>
                                            <div style={{ maxWidth: "200px", width: "100%", display: "inline-block", marginTop: "7px" }}>
                                                <Form.Label aria-label="Default select example" id="graphicType">
                                                    Süre Seçimi
                                                </Form.Label>
                                            </div>
                                            <div className='col-lg-4 fv-row'>
                                                <select
                                                    className='form-select form-select-solid form-select-lg fw-bold'
                                                    onChange={e => setChoosenTime(e.target.value)}
                                                >
                                                    <option value='0'>Seçenekler</option>
                                                    <option value='1'>Zaman (saat)</option>
                                                    <option value='2'>Trigger sayısı (çekme sayısı)</option>
                                                    <option value='3'>Taşıyabileceği max ağırlık</option>
                                                </select>

                                            </div>
                                        </div>

                                    </>
                                }





                            </div>

                            <Alert key={alertVariant} variant={alertVariant} show={showAlert}>
                                {alertText}
                            </Alert>
                            <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                <a className='btn btn-primary' onClick={sendData}>
                                    {!loading && 'Değişikleri Kaydet'}
                                    {loading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </a>
                            </div>

                        </form>

                    </div >
                }

            </div >
        </>

    )
}
