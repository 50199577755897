/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'
import { blackboxIdContext } from '../profile/components/contexts/BlackboxIdContext';
import { useAuth } from '../auth';
import { Alert, Form, OverlayTrigger } from 'react-bootstrap';
import { ProductTypeList } from './RelatedProductList';
import { MAINTENANCE_ADD_PRODUCT, BLACKBOXES_DEVICE_LIST_URL, BLACKBOXES_DRIVERCOMMAND_GETALL_URL, GET_PRODUCT_LIST_BY_BRAND_TEXT, ADD_PREDICTIVEMAINTENANCE } from '../../../urls';
import { get } from 'http';
import { useDebounce } from '../../../_elfaiotbbx/helpers';



interface Product {
    id: string
    brand: string;
    model: string;
}

const AddAIProduct: React.FC = () => {

    const { currentUser, logout } = useAuth()
    const [id, setId] = useState(currentUser?.id)
    const currentBlackBoxId = useContext(blackboxIdContext);
    const token = currentUser?.token
    const [loading, setLoading] = useState(false)


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [alertVariant, setAlertVariant] = useState("danger")
    const [showAlert, setShowAlert] = useState(false)
    const [alertText, setAlertText] = useState("Aynı node id'li cihaz eklenemez")
    const [productType, setProductType] = useState("0")
    const defaultValue = {
        "indicator": false,
        "angle": false,
        "speed": false,
        "canio": false
    }
    const [selectedProductType, setSelectedProductType] = useState(defaultValue)
    const [indicatorList, setIndicatorList] = useState<any>([])
    const [motorList, setMotorList] = useState<any>([])
    const [angleLimList, setAngleLimList] = useState<any>([])
    const [selectedIndicator, setSelectedIndicator] = useState()
    const [selectedAngleLim, setSelectedAngleLim] = useState()
    const [selectedMotor, setSelectedMotor] = useState()
    const [selectedCanio, setSelectedCanio] = useState()
    const [canioList, setCanioList] = useState<any>([])
    const [description, setDescription] = useState("")
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const [productList, setProductList] = useState<Product[]>([])
    const [maintenanceInterval, setMaintenanceInterval] = useState<any>(12)
    const [initializeInputList, setInitializeInputList] = useState(["input seç", "Input-0", "Input-1", "Input-2", "Input-3", "Input-4", "Input-5",
        "Input-6", "Input-7", "Input-8", "Input-9", "Input-10", "Input-11", "Input-12", "Input-13", "Input-14",
        "Input-15",])
    const [lastInput, setLastInput] = useState("")
    const changeLastInput = (input) => { setLastInput(input); }

    const getCanioInput = () => {
        return initializeInputList.map((input, index) => (
            <option key={index} value={input.toString()}>{input}</option>
        ));
    };
    function changeInputList() {
        setInitializeInputList(initializeInputList.filter(input => input !== lastInput))
    }

    const handleSelectChange = (event) => {
        setSelectedValue(event.target.value);
        setSearchTerm(event.target.value);
    };
    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
        setSelectedValue('');  // Arama yaparken seçimi temizleyin
    };
    const createHeader = () => {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', "" + token?.toString());
        return headers
    }

    const debouncedSearchTerm = useDebounce(searchTerm, 500);
    useEffect(() => {
        if (debouncedSearchTerm) {
            fetch(GET_PRODUCT_LIST_BY_BRAND_TEXT + `?searchtext=${debouncedSearchTerm}&userId=${currentUser?.id}`, {
                method: "GET",
                headers: createHeader(),

            })
                .then(response => response.json())
                .then(data => setProductList(data))
                .catch(error => console.error('Error fetching data:', error));
        } else {
            setProductList([]);
        }
    }, [debouncedSearchTerm]);

    const getReparationType = () => {
        return (
            <>
                {/* süre */}
                <option value={0}>Seçenekler</option>
                {/* <option value={1}>Rulman</option> */}
                {/* <option value={3}>Kasnak</option> */}
                <option value={9}>Motor</option>

                {/* trigger */}
                {/* <option value={2}>Role</option> */}
                {/* <option value={4}>Kontaktör</option> */}
                <option value={6}>Buton</option>
                {/* ağrılık */}

                <option value={5}>Halat</option>
                {/* <option value={7}>Kanca</option> */}

                {/* <option value={10}>Diğer</option> */}

            </>
        )
    }
    const [dataType, setDataType] = useState(0)
    const [isTypeEven, setIsTypeEven] = useState(false)
    const changeDataType = input => {
        setDataType(input.target.value);
        
    }

    function selectProductType(device: any) {

        const selectedDeviceId = device.target.value
        setProductType(selectedDeviceId)
        console.log(selectedDeviceId)
        for (let productType of ProductTypeList) {
            // console.log(productType.productId)
            // console.log(selectedDeviceId)

            if (productType.productId == selectedDeviceId) {
                setSelectedProductType(productType)

                break
            }
            setSelectedProductType(defaultValue)
        }
       
    }


    function sendData() {
        if(productType === "9" || productType === "5"){
            const data = {
                "productId": searchTerm,
                "blackboxId": currentBlackBoxId[0],
                "productType": getProductName(productType),
                "description": description,
                "maintenanceInterval": maintenanceInterval,
                associatedDevices: {
                    "productId": searchTerm,
                    "indicatorId": selectedIndicator,
                    "craneCatMotor": selectedMotor,
                    "angleLimId": selectedAngleLim,
                    "blackboxId": currentBlackBoxId[0],
                }
            }
            
            addProduct(data)
        }else{
            const data = {
                "blackboxId": currentBlackBoxId[0],
                "description": description,
                "uid": selectedCanio,
                "ioNumber": dataType,
                "ioDeviceId": selectedValue,
            }
            
            addPredictivemaintenance(data)
        }
        

       
        
        //addProduct(data)
        // setTimeout(() => {
        //     window.location.reload();
        //   }, 1000);
        
    }

    async function addPredictivemaintenance(data: any) {
    
            //const response = await fetch(BLACKBOXES_DEVICE_LIST_URL+`?blackboxId=${blackboxDetails["serialNumber"]}`, {
            const response = await fetch(ADD_PREDICTIVEMAINTENANCE, {
                method: "POST",
                headers: createHeader(),
                body: JSON.stringify(data)
            })
            if (response.status === 401) {
                logout();
                return;
            }
            if (response.status === 200) {
                setAlertText(`Cihaz başarılı bir şekilde kaydedildi`)
                setAlertVariant("success")
                setShowAlert(true)
                setTimeout(() => setShowAlert(false), 2000)
                //setVariant("danger")
                return
            }
            else {
                setAlertText(`Hata oluştu. Bilgileri tekrar kontrol edin`)
                setAlertVariant("danger")
                setShowAlert(true)
                setTimeout(() => setShowAlert(false), 2000)
                //setVariant("danger")
                return
            }
    
        }

    function getProductName(product) {
        if (product === "1") return "ROLLER";
        else if (product === "3") return "PULLEY"
        else if (product === "5") return "ROPE"
        else if (product === "7") return "HOOK"
        else if (product === "9") return "MOTOR"
    }
    async function addProduct(sendData) {

        //const response = await fetch(BLACKBOXES_DEVICE_LIST_URL+`?blackboxId=${blackboxDetails["serialNumber"]}`, {
        const response = await fetch(MAINTENANCE_ADD_PRODUCT, {
            method: "POST",
            headers: createHeader(),
            body: JSON.stringify(sendData)
        });

        if (response.status === 401) {
            logout();
            return;
        }
        if (response.status === 200) {
            setAlertText(`Cihaz başarılı bir şekilde kaydedildi`)
            setAlertVariant("success")
            setShowAlert(true)
            setTimeout(() => setShowAlert(false), 2000)
            //setVariant("danger")
            return
        }
        if (response.status === 400) {
            setAlertText(`    Eşleştirilmiş cihaz bilgileri yanlış`)
            setAlertVariant("danger")
            setShowAlert(true)
            setTimeout(() => setShowAlert(false), 2000)
            //setVariant("danger")
            return
        }
        else {
            setAlertText(`Hata oluştu. Bilgileri tekrar kontrol edin`)
            setAlertVariant("danger")
            setShowAlert(true)
            setTimeout(() => setShowAlert(false), 2000)
            //setVariant("danger")
            return
        }

        const data = await response.json();



    }
    function setpProductDescription(e: any) {
        setDescription(e.target.value)
    }
    
    useEffect(() => {
        getDeviceList()
        getDriverList()
    }, [])

    function selectIndicator(e: any) {
        setSelectedIndicator(e.target.value)
    }
    function selectAngleLim(e: any) {
        setSelectedAngleLim(e.target.value)
    }
    function selectMotor(e: any) {
        setSelectedMotor(e.target.value)
    }
    function selectCanio(e:any){
        console.log(e.target.value)
        setSelectedCanio(e.target.value)
    }
    function selectMaintenanceInterval(e: any) {
        setMaintenanceInterval(e.target.value)
    }
    async function getDeviceList() {

        //const response = await fetch(BLACKBOXES_DEVICE_LIST_URL+`?blackboxId=${blackboxDetails["serialNumber"]}`, {
        const response = await fetch(BLACKBOXES_DEVICE_LIST_URL + `?blackboxId=${currentBlackBoxId[0]}`, {
            method: "GET",
            headers: createHeader()
        })

            .then(
                res => res.json()
            )
            .then(
                res => {
                    console.log(res)
                    setIndicatorList([
                        <option value={0}>Seçenekler</option>,
                        ...res
                            .filter((device) => device.deviceName == 2) // Şartı sağlayan cihazları filtrele
                            .map((device) => (
                                <option key={device.id} value={device.uid}>
                                    {device.description}
                                </option>
                            )) // JSX öğelerini oluştur
                    ]
                    );

                    setAngleLimList([
                        <option value={0}>Seçenekler</option>,
                        ...res
                            .filter((device) => device.deviceName == 3) // Şartı sağlayan cihazları filtrele
                            .map((device) => (
                                <option key={device.id} value={device.uid}>
                                    {device.description}
                                </option>
                            )) // JSX öğe
                    ]
                    )
                    setCanioList([
                        <option value={0}>Seçenekler</option>,
                        ...res
                            .filter((device) => device.deviceName == 4) // Şartı sağlayan cihazları filtrele
                            .map((device) => (
                                <option key={device.id} value={device.uid}>
                                    {device.description}
                                </option>
                            )) // JSX öğe
                    ]
                    )


                }

            )

    }

    async function getDriverList() {

        //const response = await fetch(BLACKBOXES_DEVICE_LIST_URL+`?blackboxId=${blackboxDetails["serialNumber"]}`, {
        const response = await fetch(BLACKBOXES_DRIVERCOMMAND_GETALL_URL + `?blackboxId=${currentBlackBoxId[0]}`, {
            method: "GET",
            headers: createHeader()
        })

            .then(
                res => res.json()
            )
            .then(
                res => {

                    //setAngleLimList(res)

                    setMotorList([
                        <option value={0}>Seçenekler</option>,
                        ...res
                            .map((device) => (
                                <option key={device.id} value={device.driverId}>
                                    {device.description}
                                </option>
                            )) // JSX öğe
                    ]
                    )
                }

            )

    }
    return (

        <>

            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_account_profile_details'
                    aria-expanded='true'
                    aria-controls='kt_account_profile_details'
                >
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Ürün Ekleme</h3>
                    </div>
                </div>
                {id != undefined &&
                    <div id='kt_account_profile_details' className='collapse show'>
                        <form noValidate className='form'>
                            <div className='card-body border-top p-9'>


                                <div className='row mb-6'>

                                    <label style={{ maxWidth: "200px", width: "100%", display: "inline-block", marginTop: "7px" }}>
                                        <span className='required'>Eklenecek Tür</span>
                                    </label>

                                    <div className='col-lg-4 fv-row'>
                                        <select
                                            className='form-select form-select-solid form-select-lg fw-bold'

                                            onClick={(e) => selectProductType(e)}
                                        >
                                            {getReparationType()}
                                        </select>
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'></div>
                                        </div>
                                    </div>

                                </div>
                                <div className='row mb-6'>
                                    <div style={{ maxWidth: "200px", width: "100%", display: "inline-block", marginTop: "7px" }}>
                                        <Form.Label aria-label="Default select example" id="graphicType">
                                            Açıklama
                                        </Form.Label>
                                    </div>
                                    <div style={{ maxWidth: "500px", width: "100%", display: "inline-block", }}>
                                        <Form.Control aria-label="Default select example" id="graphicType" onChange={e => setpProductDescription(e)}>

                                        </Form.Control>
                                    </div>
                                </div>
                                {(productType === "9" || productType === "5") &&
                                <div className='row mb-6'>
                                    <div style={{ maxWidth: "200px", width: "100%", display: "inline-block", marginTop: "7px" }}>
                                        <Form.Label aria-label="Default select example" id="graphicType">
                                            Bakım Aralığı (Ay)
                                        </Form.Label>
                                    </div>
                                    <div style={{ maxWidth: "500px", width: "100%", display: "inline-block", }}>
                                        <Form.Control aria-label="Default select example" id="graphicType" onChange={e => selectMaintenanceInterval(e)}>

                                        </Form.Control>
                                    </div>
                                </div>
}
                                {selectedProductType.indicator &&
                                    <div className='row mb-6'>
                                        <div style={{ maxWidth: "200px", width: "100%", display: "inline-block", marginTop: "7px" }}>
                                            <Form.Label aria-label="Default select example" id="graphicType">
                                                İlişkili indikatörü seçin
                                            </Form.Label>
                                        </div>
                                        <div style={{ maxWidth: "500px", width: "100%", display: "inline-block", }}>
                                            <select
                                                className='form-select form-select-solid form-select-lg fw-bold'

                                                onClick={(e) => selectIndicator(e)}
                                            >
                                                {indicatorList}
                                            </select>
                                        </div>

                                    </div>
                                }
                                {selectedProductType.angle &&
                                    <div className='row mb-6'>
                                        <div style={{ maxWidth: "200px", width: "100%", display: "inline-block", marginTop: "7px" }}>
                                            <Form.Label aria-label="Default select example" id="graphicType">
                                                İlişkili halat açıyı seçin
                                            </Form.Label>
                                        </div>
                                        <div style={{ maxWidth: "500px", width: "100%", display: "inline-block", }}>
                                            <select
                                                className='form-select form-select-solid form-select-lg fw-bold'

                                                onClick={(e) => selectAngleLim(e)}
                                            >
                                                {angleLimList}
                                            </select>
                                        </div>
                                    </div>
                                }
                                {selectedProductType.speed &&
                                    <div className='row mb-6'>
                                        <div style={{ maxWidth: "200px", width: "100%", display: "inline-block", marginTop: "7px" }}>
                                            <Form.Label aria-label="Default select example" id="graphicType">
                                                İlişkili moturu seçin
                                            </Form.Label>
                                        </div>
                                        <div style={{ maxWidth: "500px", width: "100%", display: "inline-block", }}>
                                            <select
                                                className='form-select form-select-solid form-select-lg fw-bold'

                                                onClick={(e) => selectMotor(e)}
                                            >
                                                {motorList}
                                            </select>
                                        </div>
                                    </div>
                                }
                                {selectedProductType.canio &&
                                    <div>
                                        <div className='row mb-6'>

                                            <div style={{ maxWidth: "200px", width: "100%", display: "inline-block", marginTop: "7px" }}>
                                                <Form.Label aria-label="Default select example" id="graphicType">
                                                İlişkili canio cihazı seçimi
                                                </Form.Label>
                                            </div>
                                            <div style={{ maxWidth: "500px", width: "100%", display: "inline-block", }}>
                                                <select
                                                    className='form-select form-select-solid form-select-lg fw-bold'

                                                    onClick={(e) => selectCanio(e)}
                                                >
                                                    {canioList}
                                                </select>
                                            </div>


                                        </div>
                                        <div style={{ maxWidth: "200px", width: "100%", display: "inline-block", marginTop: "7px" }}>
                                            <Form.Label aria-label="Default select example" id="graphicType">
                                                İlişkili canio girişini seçin
                                            </Form.Label>
                                        </div>
                                        <div style={{ maxWidth: "500px", width: "100%", display: "inline-block", }}>
                                            <select
                                                className='form-select form-select-solid form-select-lg fw-bold'

                                                onClick={(e) => changeDataType(e)}
                                            >
                                                {getCanioInput()}
                                            </select>
                                        </div>
                                    </div>
                                }
                                <div className='row mb-6'>
                                    <label htmlFor="searchBar">Ara:</label>
                                    <input
                                        type="text"
                                        id="searchBar"
                                        className="form-control"
                                        value={searchTerm}
                                        onChange={handleInputChange}
                                        placeholder="Aramak için bir şeyler yazın..."
                                    />
                                </div >

                                <div >
                                    {productList.length > 0 && (
                                        <select
                                            id="comboBox"
                                            className="form-control mt-2"
                                            value={selectedValue}
                                            onChange={handleSelectChange}
                                            size={5}  // Birden fazla seçeneği göstermek için
                                        >
                                            <option value="">Seçiniz...</option>
                                            {productList.map(item => (
                                                <option key={item.id} value={item.id}>
                                                    {item.brand + " " + item.model}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                </div>
                            </div>

                            <Alert key={alertVariant} variant={alertVariant} show={showAlert}>
                                {alertText}
                            </Alert>
                            <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                <a className='btn btn-primary' onClick={sendData}>
                                    {!loading && 'Değişikleri Kaydet'}
                                    {loading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </a>
                            </div>

                        </form>

                    </div >
                }

            </div >
        </>
    )
}

export { AddAIProduct }
